import {css, Theme, useTheme} from "@emotion/react";

import {AdPlacement} from "../../advertisement/components/AdPlacement";
import {AdTargetingPageName, getAdTargetingString} from "../../advertisement/get_ad_targeting";
import {PlacementDest} from "../../advertisement/PlacementDest";
import {PlacementPage} from "../../advertisement/PlacementPage";
import {getContest100kUrl} from "../../offer/utils/constants_offer";

import placeholder360x337 from "../../../assets/ad_placeholders/konkurs_360x337.png";
import placeholder1239x242 from "../../../assets/ad_placeholders/konkurs_1239x242.png";

const placeholder = {
    image: placeholder1239x242,
    url: getContest100kUrl({campaign: "glowna"})
};

const placeholderMobile = {
    image: placeholder360x337,
    url: getContest100kUrl({campaign: "glowna"})
};

export const HomePageAdPlacements = () => {
    const theme = useTheme();
    const adTargeting = getAdTargetingString({pageNames: AdTargetingPageName.HOMEPAGE});

    return (
        <div css={adPlacementsWrapper}>
            <div css={adPlacementHolderDesktop}>
                <AdPlacement
                    dest={PlacementDest.DESKTOP}
                    page={PlacementPage.homepage__desktop}
                    placeholder={placeholder}
                    breakpoint={theme.breakpoints.numeric.screen_sm}
                    target={adTargeting}
                />
            </div>

            <div css={adPlacementHolderMobile}>
                <AdPlacement
                    dest={PlacementDest.MOBILE}
                    page={PlacementPage.homepage__mobile}
                    placeholder={placeholderMobile}
                    breakpoint={theme.breakpoints.numeric.screen_sm}
                    target={adTargeting}
                />
            </div>
        </div>
    );
};

const adPlacementsWrapper = (theme: Theme) => css`
    padding: 3rem 0 0;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 5rem 0 0;
    }
`;

const adPlacementHolderDesktop = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        display: block;
    }
`;

const adPlacementHolderMobile = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.screen_sm}) {
        display: none;
    }
`;
