import * as React from "react";
import {useCallback, useEffect, useState, useTransition} from "react";
import {connect, useDispatch} from "react-redux";
import {RouteComponentProps, useLocation} from "react-router";
import {useTheme} from "@emotion/react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {bindActionCreators, Dispatch} from "redux";
import {appLink, parseOfferSlugToObject} from "@web2/gh_routes";
import {useRoutedModalState} from "@web2/modal2";
import {usePrevious} from "@web2/react_utils";
import {BarLoader} from "@web2/ui_utils";
import {useUserDevice} from "@web2/user-device";

import {useRouteHandler} from "../../../../client/utils/use_route_handler";
import {setFavouriteOffer} from "../../../app/actions/load_local_storage_favourites_to_store";
import {useTransitioningHistoryPush} from "../../../app/hooks/use_transitioning_history_push";
import {ILocationStatistics} from "../../../app/interfaces/response/location_statistics";
import {IOfferInvestment, IOfferListOfferResponse} from "../../../app/interfaces/response/offer_list";
import {IOfferListApiResponseMeta} from "../../../app/interfaces/response/server_list_response";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {Search} from "../../../search/components/Search";
import {getThemeBreakpoint, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {IGtmOffer} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_event_typings";
import {gtmOfferClick} from "../../../tracking/google_tag_manager/ecommerce_events/gtm_offer_click";
import {GtmContactType} from "../../../tracking/google_tag_manager/utils/gtm_contact_type";
import {GtmSource} from "../../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../../tracking/view_type/view_type";
import {IViewTypeMetaData} from "../../../tracking/view_type/view_type_actions";
import {setOfferModalStateActionTypes} from "../../actions/toggle_offer_modal_at_route";
import {handleOfferBoxMetaClick} from "../../detail/components/offer_box/OfferBox";
import {ILocation} from "../actions/fetch_location_by_slug_at_route";
import {IOfferListQuery} from "../reducers/offer_list_reducer";
import {getOfferListAffixes} from "../utils/get_offer_list_affixes";
import {useOfferListTracking} from "./map/desktop/use_offer_list_tracking";
import {OfferListDynamicBody} from "./OfferListDynamicBody";
import {OfferListDynamicMobileMaps} from "./OfferListDynamicMobileMaps";
import {SortType} from "./OfferListSortButton";

interface IStateProps {
    locationData: {
        location: ILocation | null;
        statistics: ILocationStatistics | {} | null;
        recommended: ILocation[];
    };
    latestQuery: IOfferListQuery;
    offersData: {
        meta: IOfferListApiResponseMeta;
        collection_count: number;
        offers: IOfferListOfferResponse[];
        requestState: RequestState;
        page: number;
        pageCount: number;
    };
    currentModalOfferSlug: string | null;
    investment: {
        investment: IOfferInvestment | null;
        requestState: RequestState;
    };
    favouriteOffers: string[];
    visitedOffers: string[];
    viewType: ViewType | null;
    viewTypeData: Partial<IViewTypeMetaData>;
}
interface IActionProps {
    setFavouriteOffer: typeof setFavouriteOffer;
}

export interface IOfferListDynamicProps extends IStateProps, IActionProps, RouteComponentProps<{}> {
    enableRouteHandler?: boolean;
}

export const MAPVIEW_PARAM = "mapView";

const OfferListDynamicC = (props: IOfferListDynamicProps) => {
    useRouteHandler({enableRouteHandler: props.enableRouteHandler || true});

    const {isMobile} = useUserDevice();
    const dispatch = useDispatch();
    const location = useLocation();

    const theme = useTheme();
    const {isTransitioning, transitionHistory} = useTransitioningHistoryPush();
    const [_, startTransition] = useTransition();
    const [prevPath, setPrevPath] = useState<string | null>(null);

    const {latestQuery} = props;
    const prevLatestQuery = usePrevious(latestQuery, latestQuery);

    const [isMapBig, setMapBig] = useState<boolean>(false);

    const {modalState: isMobileMapOpen, openModal: openMobileMap, closeModal: closeMobileMap} = useRoutedModalState(MAPVIEW_PARAM, 1);
    useEffect(() => {
        if (!isMobile && isMobileMapOpen) {
            closeMobileMap(true);
        }
    }, [isMobile, isMobileMapOpen]);

    const shouldDisplayLoadingBar = () => props.offersData.requestState === RequestState.Waiting;

    /**
     * Callbacks
     */

    useOfferListTracking(props);
    const {gtmListPrefix, gtmListSuffix} = getOfferListAffixes(latestQuery);

    const onMapOfferClick = useCallback(
        (e: React.MouseEvent<HTMLElement>, mapOffer: IGtmOffer) => {
            gtmOfferClick({
                offer: mapOffer,
                viewType: ViewType.OFFER_LIST_ALL,
                contactType: GtmContactType.UNKNOWN,
                gtmSource: GtmSource.UNKNOWN,
                listPrefix: gtmListPrefix,
                listSuffix: gtmListSuffix
            });

            handleOfferBoxMetaClick(e, () => goToOffer(mapOffer.slug));
        },
        [gtmListPrefix, gtmListSuffix]
    );

    const goToOffer = (slug: string, sort?: SortType) => {
        const offerLink = appLink.fullOffer.detail.base(parseOfferSlugToObject(slug));
        if (isMobile) {
            window.location.href = offerLink;
            return;
        }

        dispatch({type: setOfferModalStateActionTypes.open});
        transitionHistory(offerLink);

        const sortPrevPath = !prevLatestQuery.sort && sort ? `?sort=${sort}` : "";
        setPrevPath(location.pathname + location.search + sortPrevPath);
    };

    /**
     * Render
     */
    return (
        <div className={listingHolder}>
            <div className={pageHolder}>
                <div className={offerListSearchHolder}>
                    <Search />

                    {shouldDisplayLoadingBar() && (
                        <div className={loaderHolder}>
                            <BarLoader color={theme.colors.brand_success} width="100%" speedMultiplier={1} />
                        </div>
                    )}
                </div>

                <article className={bodyHolder}>
                    {!isMobileMapOpen ? (
                        <OfferListDynamicBody
                            isMapBig={isMapBig}
                            setMapBig={setMapBig}
                            goToOffer={goToOffer}
                            prevPath={prevPath}
                            setPrevPath={setPrevPath}
                            setFavouriteOffer={props.setFavouriteOffer}
                        />
                    ) : (
                        <OfferListDynamicMobileMaps
                            setFavouriteOffer={props.setFavouriteOffer}
                            onMapOfferClick={onMapOfferClick}
                            locationData={props.locationData}
                            collectionCount={props.offersData.collection_count}
                            favouriteOffers={props.favouriteOffers}
                            visitedOffers={props.visitedOffers}
                            viewType={props.viewType}
                            investment={props.investment}
                        />
                    )}
                </article>

                <MobileMapTriggerHolder
                    isMapOpen={isMobileMapOpen}
                    isTransitioning={isTransitioning}
                    onClick={() => {
                        startTransition(() => {
                            isMobileMapOpen ? closeMobileMap(true) : openMobileMap();
                        });
                    }}
                >
                    {isMobileMapOpen ? "Lista" : "Mapa"}
                </MobileMapTriggerHolder>
            </div>
        </div>
    );
};

function mapStateToProps(state: IStore): IStateProps {
    return {
        locationData: state.offerList.location,
        latestQuery: state.offerList.latestQuery,
        offersData: state.offerList.offers,
        currentModalOfferSlug: state.offer.offer?.slug,
        favouriteOffers: state.favourites.favourites,
        visitedOffers: state.visited.offers,
        investment: state.offerList.investment,
        viewType: state.viewType.current,
        viewTypeData: state.viewType.currentData
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionProps {
    return bindActionCreators({setFavouriteOffer}, dispatch);
}

export const OfferListDynamic = connect(mapStateToProps, mapActionsToProps)(OfferListDynamicC);
export default OfferListDynamic;

//Layout
const listingHolder = css`
    position: relative;
`;

const pageHolder = css`
    position: relative;
    width: 100%;
`;

const offerListSearchHolder = css`
    position: sticky;
    top: ${getThemeVariable("main_nav-height")};
    z-index: 3;
    width: 100%;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.1);
`;

//OfferList
const bodyHolder = css`
    background: ${getThemeVariable("colors-gray_very_bright")};

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
`;

const loaderHolder = css`
    position: absolute;
    top: 1px;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 2;
`;

const MobileMapTriggerHolder = styled.button<{isMapOpen: boolean; isTransitioning: boolean}>`
    position: ${(props) => (props.isMapOpen ? "fixed" : "sticky")};
    z-index: 7001;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    background: ${(props) => (props.isTransitioning ? "#eee" : "#fff")};
    transition: background 200ms ease;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.18);
    padding: 1.3rem 3rem;
    display: block;
    text-decoration: none;
    outline: 0;
    border: 1px solid #fff;
    cursor: pointer;
    width: 133px;
    border-radius: 2rem;
    color: ${getThemeVariable("colors-gray_dark")};
    font-size: 1.3rem;
    line-height: 1;
    text-align: center;
    user-select: none;

    @media (min-width: ${getThemeBreakpoint().screen_md}) {
        display: none;
    }
`;
